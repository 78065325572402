// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

* {
  font-family: "Open Sans", sans-serif;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.9 !important;
  cursor: not-allowed;
}

.required:after {
  color: #e32;
  content: " *";
  display: inline;
}

.icon {
  cursor: pointer;
}
.icon .modal-backdrop.show {
  opacity: 0.6;
}
.cat-icon .modal-backdrop.show {
  opacity: 0.1;
}
.cat-icon {
  cursor: pointer;
}
.fa-slack {
  opacity: 0.6;
  cursor: not-allowed;
}
.customBtn {
  padding: 0.25rem 0.45rem;
}
.carousel .card-body {
  background-color: #babdc2;
}
.border-table tr:nth-child(even) {
  background-color: #e6e6e6;
}
.pagination {
  margin: 0px;
}
.modal-backdrop.show {
  opacity: 0.1;
}
.ledger-table {
  width: 1500px;
}
.block {
  display: block;
}
.partner-table th,
.partner-table td {
  border-top: none;
}
.partner-table .card {
  border: 2px solid black;
}
.file-icon {
  background-color: grey;
  opacity: 0.7;
  padding: 5px;
  cursor: pointer;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.butn {
  padding: 4px 6px;
  margin: 5px;
}
a:link {
  text-decoration: none;
}

a.link {
  color: blue($color: #000000);
}

.error {
  margin: 0;
  padding: 0;
  color: red;
  font-size: 13px;
}

@media only screen and (min-width: 1200px) {
  .carouselResp {
    display: flex;
    justify-content: center;
    background-color: whitesmoke;
    margin: 0 100px;
    border: 5px solid grey;
    padding: 15px;
    word-break: break-word;
  }
}
@media only screen and (max-width: 992px) {
  .btnResp {
    margin-top: 10px;
  }
}


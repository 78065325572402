.medicalEstablishment-form {
    background-color: #e4e5e6;
  height: 630px;
  padding: 25px 0px;
  font-size: 14px;
}

.medicalEstablishment-form .container {
    margin-top: 30px;
  display: flex;
  width: 100%;
  max-width: 460px;
  background-color: white;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* text-align: center; */
  border-radius: 12px;
}

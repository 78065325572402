.multi-invoice .table-container {
    margin: 0px 0px 20px 0px;
    width: 100%;
}

.multi-invoice .table-container table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
}

.multi-invoice .table-container th, 
.multi-invoice .table-container td {
    border: 0.5px solid #BEBEBE;
    padding: 8px;
    text-align: left;
}

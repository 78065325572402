.specialization-form {
  background-color: #eaefff;
  height: 630px;
  padding: 25px 0px;
  font-size: 14px;
}

.specialization-form .heading {
  color: #005aa9;
  font-size: 16px;
  margin-bottom: 10px;
}

.specialization-form .container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.specialization-form .user-details {
  border-radius: 13px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 0px 30px;
  position: relative;
  width: 40%;
  height: auto;
}

.radio-box label {
  padding-right: 15px;
}

.radio-box input {
  margin-right: 6px;
  cursor: pointer;
}

.specialization-form img {
  margin: 10px auto;
}

@media (max-width: 767px) {
  .specialization-form {
    padding: 8px 0px;
  }

  .specialization-form .container {
    display: flex;
    flex-direction: column;
  }

  .specialization-form .user-details {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .specialization-form .user-details-container {
    padding: 0px 20px;
  }

  .specialization-form .img-container {
    display: flex;
  }

  .specialization-form .img-container>div {
    margin-right: 2%;
  }

  .specialization-form .heading {
    font-size: 13px;
  }

  .specialization-form .radio-box label {
    font-size: 12px;
  }

}

/* Chrome, Safari, Edge, Opera */
.specialization-form input::-webkit-outer-spin-button,
.specialization-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.specialization-form input[type=number] {
  -moz-appearance: textfield;
}

.quote-card .left-text {
  font-size: 12px;
  text-align: left;
  color: #696969;
  margin-bottom: 13px;
}

.quote-card .amount {
  font-family: PTSans;
  font-size: 22px;
  line-height: 1.64;
  text-align: left;
  color: #005aa9;
  font-weight: 600;
}

.quote-card .bottom-text {
  font-family: PTSans;
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
  color: #ee5b35;
}

.quote-card .btn1 {
  font-size: 13px;
  padding: 8px 6px;
  background: #fff;
  color: #005aa9;
  border-radius: 3px;
  border: solid 0.6px #005aa9;
  display: inline-block;
  margin-right: 10px;
  outline: none;
}

.quote-card .btn2 {
  font-size: 13px;
  padding: 8px 7px;
  margin: 0 9px 0 5px;
  color: #fff;
  background-color: #ee5b35;
  border-radius: 3px;
  border: solid 0.6px #ee5b35;
  display: inline-block;
  outline: none;
}

.quote-card .btn2:disabled {
  cursor: not-allowed;
}

.quote-card-details .heading {
  font-size: 14px;
  color: #f15a23;
  margin-top: 2px;
}

.pay-btn {
  height: 35px;
  width: 120px;
  color: white;
  background-color: #4db751;
  outline: none;
  border: 1px solid #4db751;
  border-radius: 6px;
  float: right;
}

.quote-card .pdf {
  cursor: pointer;
  width: 29px;
  height: 44px;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  vertical-align: middle;
  margin-right: 10px;
  background: url('/assets/img/avatars/pdf-download.svg') no-repeat !important;
  display: inline-block;
  outline: none;
}

.quote-card .btn1:disabled {
  cursor: not-allowed;
}

.form-control::placeholder {
  color: #bebbbb;
}
.loading-container {
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1111;
  }
  
  .lds-ring {
    top: 30%;
    position: absolute;
    left: 40%;
    transform: translate(-50%, -50%);
  }
  
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    border: 5px solid #0158a9;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0158a9 transparent transparent transparent;
  }
  
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  
  .ledger-table .table-heading {
    vertical-align: top;
  }

  .filter-dropdown {
    width: 80%;
    height: 28px;
  }
  
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
.custom-select-new .input-box{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    position: relative;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;   
}

.custom-select-new .selected-text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-select-new .tooltip {
    position: absolute;
    bottom: 100%;
    left: 0;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
    display: none;
    z-index: 10;
    font-size: 12px;
}
  
.custom-select-new .input-box:hover .tooltip {
    display: block;
}

.custom-select-new label{
    font-size: 14px;
    font-family: sans-serif;
    color: #23282c;
}

.custom-select-new .dropdown-list {
    position: absolute;
    z-index: 10;
    display: block;
    width: 94%;
    max-height: 300px;
    overflow-y: auto;
    padding: 5px 5px;
    font-size: 13px;
    list-style: none;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    cursor: pointer;
}

.custom-select-new .dropdown-list li {
    padding: 4px 4px;
    border-bottom: 1px solid #e4e7ea;
}

.custom-select-new .dropdown-list li:hover{
    background-color: #c4d2e0;
}

.custom-select-new select:disabled{
    cursor: not-allowed;
}